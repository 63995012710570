import moment from "moment";

export const createAssignmentReportData = (itemMap) => {
  const items = Object.values(itemMap);

  return items.map((el) => {
    const {
      contractsAndInvoices,
      rentProductsAmount,
      rewardAmount,
      rewardAmountWithoutVat,
      rewardAmountVat,
      realtorRewards,
    } = getApartmentAssignmentInvoiceData(el);

    return {
      Toimeksiantajat: el.owners.map((el) => el.name).join("\n"),
      Vuokrakohde: getApartmentAddress(el),
      "Päällekkäisiä sopimuksia": el.concurrentContracts,
      Toimeksianto: el.assignment.assignmentEnd
        ? `${el.assignment.assignmentStart} - ${el.assignment.assignmentEnd}`
        : `${el.assignment.assignmentStart} - `,

      "Laskut ja sopimukset": contractsAndInvoices,
      Palkkiomalli: getAssignmentRewardData(el.assignment),
      "Laskutetut vuokrat": rentProductsAmount,
      "Palkkio sis. alv": rewardAmount,
      "Palkkio alv": rewardAmountVat,
      "Palkkio ilman alv": rewardAmountWithoutVat,
      Välittäjäpalkkiot: realtorRewards,
      Kommentti: el.assignment.comments,
    };
  });
};

export const createNetIncomeReportData = (
  accountingBase,
  timeRangeApartmentMap,
  cumulativeApartmentMap,
  end
) => {
  if (accountingBase == "accountDate") {
    return createAccountingBaseData(
      accountingBase,
      timeRangeApartmentMap,
      cumulativeApartmentMap,
      end
    );
  } else if (accountingBase == "paymentDate") {
    return createPaymentBaseData(
      accountingBase,
      timeRangeApartmentMap,
      cumulativeApartmentMap,
      end
    );
  }
};

export const createContractReportData = (contractMap) => {
  const items = Object.values(contractMap);

  return items.map((el) => {
    return {
      "Sopimusnro.": el.contractNumber,
      Vuokrasopimus: el.dates,
      Vuokrakohde: el.address,
      "Vuokrakohteen nro.": el.apartmentNumber,
      Vuokralainen: el.tenant,
      "Viimeisin laskun nro.": el.invoiceNumber,
      "Vuokra brutto": el.rentProductTotalWithTaxes,
      "Vuokratuotteita yht.": el.rentProductCount,
      "Vastaanotetut vakuudet": el.depositAmount,
      Vakuustavoite: el.depositTargetAmount,
    };
  });
};

// Create accounting base data

function createAccountingBaseData(
  accountingBase,
  timeRangeApartmentMap,
  cumulativeApartmentMap,
  end
) {
  const startOfYear = moment(end).startOf("year").format("DD.MM.YYYY");
  const endOfYear = moment(end).endOf("year").format("DD.MM.YYYY");

  // Variables
  const items = [];
  const cumulativeKey = `Netto €\n${startOfYear}-${endOfYear}`;

  for (const id in timeRangeApartmentMap) {
    const el = timeRangeApartmentMap[id];

    items.push({
      Taloyhtiö: el.condominium,
      Osoite: el.address,
      "Vuokrakohteen\nnumero": el.apartmentNumber ? el.apartmentNumber : "",
      "Pinta-ala": el.area || "",
      Vuokrasopimus: getRentalContractDates(el.contract),
      Vuokrasaatavat: parseFloat(el.rent.subAmountWithTax.toFixed(2)),
      Vesimaksusaatavat: parseFloat(el.water.subAmountWithTax.toFixed(2)),
      Autopaikkasaatavat: parseFloat(el.parking.subAmountWithTax.toFixed(2)),
      Mediamaksusaatavat: parseFloat(el.media.subAmountWithTax.toFixed(2)),
      "Muut saatavat": parseFloat(el.other.subAmountWithTax.toFixed(2)),
      "Hoitokulut\n(hoitovastike + vesi)": parseFloat(
        (el.maintenanceChargesTotal + el.waterChargesTotal).toFixed(2)
      ),
      Rahoitusvastikkeet: parseFloat(el.totalLoans.toFixed(2)),
      "Muut kulut": parseFloat(el.totalReceipts.toFixed(2)),
      Netto: parseFloat(calcNetAmount(accountingBase, el).toFixed(2)),
      "Netto €/m²": el.area
        ? parseFloat((calcNetAmount(accountingBase, el) / el.area).toFixed(2))
        : "Ei laskettavissa",
      [cumulativeKey]: parseFloat(
        calcNetAmount(accountingBase, cumulativeApartmentMap[id]).toFixed(2)
      ),
    });
  }

  return items;
}

function createPaymentBaseData(accountingBase, timeRangeApartmentMap, cumulativeApartmentMap, end) {
  const startOfYear = moment(end).startOf("year").format("DD.MM.YYYY");
  const endOfYear = moment(end).endOf("year").format("DD.MM.YYYY");

  // Variables
  const items = [];
  const cumulativeKey = `Netto €\n${startOfYear}-${endOfYear}`;

  for (const id in timeRangeApartmentMap) {
    const el = timeRangeApartmentMap[id];

    items.push({
      Taloyhtiö: el.condominium,
      Osoite: el.address,
      "Vuokrakohteen\nnumero": el.apartmentNumber ? el.apartmentNumber : "",
      "Pinta-ala": el.area || "",
      Vuokrasopimus: getRentalContractDates(el.contract),
      Maksusuoritukset: parseFloat(el.totalPayments.toFixed(2)),
      "Hoitokulut\n(hoitovastike + vesi)": parseFloat(
        (el.maintenanceChargesTotal + el.waterChargesTotal).toFixed(2)
      ),
      Rahoitusvastikkeet: parseFloat(el.totalLoans.toFixed(2)),
      "Muut kulut": parseFloat(el.totalReceipts.toFixed(2)),
      Netto: parseFloat(calcNetAmount(accountingBase, el).toFixed(2)),
      "Netto €/m²": el.area
        ? parseFloat((calcNetAmount(accountingBase, el) / el.area).toFixed(2))
        : "Ei laskettavissa",
      [cumulativeKey]: parseFloat(
        calcNetAmount(accountingBase, cumulativeApartmentMap[id]).toFixed(2)
      ),
    });
  }

  return items;
}

// HELPERS
function calcNetAmount(accountingBase, el) {
  if (accountingBase == "accountDate") {
    return (
      el.rent.subAmountWithTax +
      el.water.subAmountWithTax -
      el.maintenanceChargesTotal -
      el.waterChargesTotal -
      el.totalLoans
    );
  } else if (accountingBase == "paymentDate") {
    return el.totalPayments - el.maintenanceChargesTotal - el.waterChargesTotal - el.totalLoans;
  } else return 0;
}

function getRentalContractDates(contract) {
  if (!contract) return "";
  const startDate = contract.startDateFormatted;
  const endDate = contract.endDateFormatted || "";
  return `${startDate} - ${endDate}`;
}

function getApartmentAddress(apartment) {
  const address = apartment.address;
  const apartmentNumber = apartment.apartmentNumber || null;

  return apartmentNumber ? `${address} ${apartmentNumber}` : address;
}

function getApartmentAssignmentInvoiceData(item) {
  const rewardType = item.assignment.rewardType;
  let contractsAndInvoices = "";
  let rentProductsAmount = 0;
  let rewardAmount = 0;
  let rewardAmountWithoutVat = 0;
  let rewardAmountVat = 0;
  let realtorRewards = "";

  if (rewardType === "percent") {
    const invoices = item.invoiceData;

    invoices.forEach((el) => {
      contractsAndInvoices += `Sopimus ${el.contractNumber}, lasku ${el.invoiceNumber}`;
      rentProductsAmount += el.rentProductsAmount;
      rewardAmount += el.rewardAmount;
      rewardAmountWithoutVat += el.rewardAmountWithoutVat;
      rewardAmountVat += el.rewardAmountVat;
      realtorRewards += el.realtorRewards
        .map((r) => `${r.name} (${r.rewardPercent} %) ${r.rewardAmount} €`)
        .join("\n");
    });
  } else if (rewardType === "fixed") {
    rewardAmount = parseFloat(item.assignment.calculatedFixedAmount.toFixed(2));
    rewardAmountWithoutVat = parseFloat((rewardAmount / 1.255).toFixed(2));
    rewardAmountVat = parseFloat((rewardAmount - rewardAmount / 1.255).toFixed(2));
    realtorRewards += item.assignment.realtors
      .map(
        (r) =>
          `${r.name} (${r.rewardPercent} %) ${calcRealtorRewardAmountFixed(
            r.rewardPercent,
            rewardAmountWithoutVat
          )} €`
      )
      .join("\n");
  }

  return {
    contractsAndInvoices,
    rentProductsAmount,
    rewardAmount,
    rewardAmountWithoutVat,
    rewardAmountVat,
    realtorRewards,
  };
}

function calcRealtorRewardAmountFixed(percent, amount) {
  return parseFloat((amount * (percent / 100)).toFixed(2));
}

function getAssignmentRewardData(assignment) {
  let amount;
  if (assignment.rewardType === "percent") {
    amount = `${assignment.percent} %`;
  } else if (assignment.rewardType === "fixed") {
    amount = `${assignment.fixedAmountPerMonth} €`;
  }

  let rewardType = "";

  switch (assignment.rewardType) {
    case "percent":
      rewardType = "Prosentti";
      break;
    case "fixed":
      rewardType = "Kiinteä / kk";
      break;
    default:
      rewardType = "Prosentti";
  }

  return `${rewardType} (${amount})`;
}
